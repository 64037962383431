import { Text } from '@teamsparta/design-system';
import { Flex } from '@teamsparta/design-system';
import Image from 'next/image';

import { LoggingClick } from '@/components/common/LoggingClick';
import { useBoolean } from '@/hooks/common';
import { useIsDesktop } from '@/hooks/common/useIsDesktop';
import { convertNewlineToJSX } from '@/lib/utils/convertNewLineToJSX';
import { Card } from '@/pages/hub/components/Review/components/Card';
import { addEllipsis } from '@/pages/plus/shared/components/Landing/Reviews/logic';

import * as S from './style';

interface ReviewCardProps {
  title: string;
  contents: string[];
  author: string;
  career: string;
  course: string;
}

export function ReviewCard({
  title,
  contents,
  author,
  career,
  course,
}: ReviewCardProps) {
  const [expanded, , setExpanded] = useBoolean(false);
  const isDesktop = useIsDesktop();

  const ellipsisText = addEllipsis(contents[0], isDesktop ? 60 : 54);

  return (
    <Card
      topAddon={
        <Image
          src='/images/icons/double_quote.svg'
          alt=''
          width={18.75}
          height={15}
        />
      }
      title={
        <Text as='h4' mFont='mTitle2' wFont='wTitle4' color='neutralDay.white'>
          {convertNewlineToJSX(title)}
        </Text>
      }
      content={
        <>
          {expanded ? (
            contents.map((content) => (
              <Text
                key={content}
                as='p'
                mFont='mBody4'
                wFont='wBody4'
                color='neutralDay.gray60'
              >
                {content}
              </Text>
            ))
          ) : (
            <Text
              as='p'
              mFont='mBody4'
              wFont='wBody4'
              color='neutralDay.gray60'
            >
              {ellipsisText}
              <LoggingClick
                logName='hh_hub_ReviewCard_click'
                data={{
                  button_title: course + ' 더보기',
                }}
              >
                <S.MoreButton onClick={setExpanded}>더보기</S.MoreButton>
              </LoggingClick>
            </Text>
          )}
        </>
      }
      bottomText={
        <Flex gap={8} align='center'>
          <Text as='span' mFont='wCaption1' color='neutralDay.gray20'>
            {author}
          </Text>
          <S.BottomTextDivider />
          <Text as='span' mFont='wCaption1' color='neutralDay.gray20'>
            {career}
          </Text>
        </Flex>
      }
    />
  );
}
