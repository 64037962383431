import { Text } from '@teamsparta/design-system';
import { Flex } from '@teamsparta/design-system';
import Image from 'next/legacy/image';
import Link from 'next/link';

import { LoggingClick } from '@/components/common/LoggingClick';
import { convertNewlineToJSX } from '@/lib/utils/convertNewLineToJSX';
import { applyOpacityToHexColor } from '@/styles/utils/applyOpacityToHexColor';

import { HUB_COURSES } from './data';
import * as S from './style';

export default function HubPageCoursesOrganism() {
  return (
    <S.Wrapper id='all-courses'>
      <S.Container>
        <Text
          as='h2'
          mFont='mHeader1'
          wFont='wHeader2'
          color='neutralDay.white'
        >
          취업부터 역량강화까지
          <br />
          항해99 전체 코스
        </Text>

        <Flex.Column gap={40}>
          {HUB_COURSES.map(({ courses, title: groupTitle }) => (
            <S.GroupContainer key={groupTitle}>
              <Text
                as='h4'
                mFont='mTitle1'
                wFont='wTitle1'
                color='neutralDay.white'
              >
                {groupTitle}
              </Text>
              <S.CardContainer>
                {courses.map((data) => (
                  <LoggingClick
                    key={data.title}
                    logName='hh_hub_BottomCourseCard_click'
                    data={{
                      course_title: data.title,
                    }}
                  >
                    <Link
                      href={data.href}
                      passHref
                      style={{ width: '100%' }}
                      target={data?.outLink ? '_blank' : ''}
                    >
                      <S.Card>
                        <Flex.Column gap={12}>
                          <Flex.Column gap={2}>
                            <Text
                              as='h5'
                              mFont='mTitle2'
                              wFont='wTitle2'
                              color='neutralDay.white'
                            >
                              {data.title}
                            </Text>
                            <Text
                              as='span'
                              mFont='mBody3'
                              wFont='wBody3'
                              color={applyOpacityToHexColor('#ff2b24', 0.8)}
                            >
                              {data.hashTag}
                            </Text>
                          </Flex.Column>
                          <Text
                            as='p'
                            mFont='mBody4'
                            wFont='wBody4'
                            color='neutralDay.gray60'
                          >
                            {convertNewlineToJSX(data.desc)}
                          </Text>
                        </Flex.Column>
                        <S.CardArrow>
                          <Image
                            width={12}
                            height={12}
                            objectFit='contain'
                            alt='오른쪽 화살표'
                            src='/images/icons/ic_arrow_upper_right.png'
                          />
                        </S.CardArrow>
                      </S.Card>
                    </Link>
                  </LoggingClick>
                ))}
              </S.CardContainer>
            </S.GroupContainer>
          ))}
        </Flex.Column>
      </S.Container>
    </S.Wrapper>
  );
}
